type QueryParams<T extends readonly string[]> = {
  [K in T[number]]: string;
};

/**
 * Parses the query parameters from a URL search string.
 *
 * @template T - A readonly array of string keys representing the query parameter keys.
 * @param {string} search - The URL search string to parse.
 * @param {T} queryParamKeys - An array of query parameter keys to extract from the search string.
 * @returns {Partial<QueryParams<T>>} An object containing the parsed query parameters.
 */
/**
 * Parses query parameters from a URL search string.
 *
 * @example
 * ```typescript
 * const search = "?name=John&age=30&city=NewYork";
 * const queryParamKeys = ["name", "age", "city"] as const;
 *
 * const parsedParams = parseQueryParams(search, queryParamKeys);
 * console.log(parsedParams); // Output: { name: "John", age: "30", city: "NewYork" }
 * ```
 *
 * @param search - The URL search string containing query parameters.
 * @param queryParamKeys - An array of query parameter keys to extract from the search string.
 * @returns An object containing the extracted query parameters.
 */
export const parseQueryParams = <T extends readonly string[]>(
  search: string,
  queryParamKeys: T,
): Partial<QueryParams<T>> => {
  const query = new URLSearchParams(search);

  return queryParamKeys.reduce<Partial<QueryParams<T>>>((acc, key) => {
    acc[key as T[number]] = query.get(key) || "";
    return acc;
  }, {});
};
