export const demoQueryParamKeys = [
  "shopPrefix",
  "useLegacyInteraction",
  "shouldInitializeFitFinder",
  "currentItemSubgroupId",
  "shortSid",
  "sid",
  "shopSessionId",
  "userId",
  "shopLanguage",
  "shopCountry",
] as const;
export type DemoQueryParameter = (typeof demoQueryParamKeys)[number];
