import { parseQueryParams } from "@fit-finder-sdk/shared/src/query-parameters/query-parser.ts";
import { demoQueryParamKeys } from "@fit-finder-sdk/shared/src/query-parameters/query-parameter-keys.constant";
import {
  CountryCode,
  LanguageCode,
} from "../../../packages/client-api/dist/io-ts-gen-types";

const queryParams = parseQueryParams(location.search, demoQueryParamKeys);

export const shouldUseLegacyInteraction = (): boolean => {
  return queryParams.useLegacyInteraction === "true";
};

export const shouldInitializeFitFinder = (): boolean => {
  return queryParams.shouldInitializeFitFinder === "true";
};

export const getCurrentItemSubgroupId = (): string => {
  return decodeURIComponent(queryParams.currentItemSubgroupId || "");
};

export const getShortSessionId = (): string => {
  return decodeURIComponent(queryParams.shortSid || "shortSid");
};

export const getSessionId = (): string => {
  return decodeURIComponent(queryParams.sid || "sessionId");
};

export const getShopSessionId = (): string => {
  return decodeURIComponent(queryParams.shopSessionId || "ssidCookie");
};

export const getUserId = (): string => {
  return decodeURIComponent(queryParams.userId || "");
};

export const getShopLanguage = () => {
  return decodeURIComponent(queryParams.shopLanguage || "en") as LanguageCode;
};

export const getShopCountry = () => {
  return decodeURIComponent(queryParams.shopCountry || "DE") as CountryCode;
};

export const getShopPrefix = () => {
  return decodeURIComponent(queryParams.shopPrefix || "");
};
