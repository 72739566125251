import FitFinderSdk from "@fit-finder-sdk/fit-finder/dist/fit-finder-sdk";
import thumbnailUrl from "../assets/images/300x300.png";
import { useEffect, useState } from "preact/hooks";
import { BehaviorSubject } from "rxjs";
import type { IntegrationConfiguration } from "@fit-finder-sdk/fit-finder/src/setup-layer.models";
import {
  getCurrentItemSubgroupId,
  getSessionId,
  getShopCountry,
  getShopLanguage,
  getShopSessionId,
  getShortSessionId,
  getUserId,
  shouldInitializeFitFinder,
  shouldUseLegacyInteraction,
} from "./query-params";

type IntegrationOptions = {
  fitFinderSdkHost: string;
  options: IntegrationConfiguration;
};

type LegacyIntegrationOptions = {
  fitFinderSdkHost: string;
  legacyFitFinderSdkHost: string;
};

const defaultCurrentItemSubgroupId = "01T71Q3-GLW";
const currentItemSubgroupId =
  getCurrentItemSubgroupId() || defaultCurrentItemSubgroupId;

const mockedIntegrationInterfaceData: IntegrationConfiguration = {
  shop: {
    shopPrefix: "alioop",
    shopLanguage: getShopLanguage(),
    shopCountry: getShopCountry(),
    userLanguage: "en",
    blockedLocales: [],
    rootDomain: "localhost",
    shopSessionId: getShopSessionId(),
    sessionId: getSessionId(),
    shortSid: getShortSessionId(),
    consent: () => ({ hasAnalyticsConsent: true }),
    userId: getUserId(),
  },
  product: {
    thumbnailUrl,
    sizes: [
      {
        isAvailable: true,
        value: "36",
      },
      {
        isAvailable: true,
        value: "38",
      },
      {
        isAvailable: true,
        value: "40",
      },
      {
        isAvailable: true,
        value: "42",
      },
      {
        isAvailable: true,
        value: "44",
      },
    ],
    currentItemSubgroupId: new BehaviorSubject(currentItemSubgroupId),
    allItemSubgroupIds: [currentItemSubgroupId],
    addToCart: (itemSubgroupId: string, size: string) => {
      console.log("Integration function - Add to cart:", itemSubgroupId, size);
    },
    buttons: [
      {
        key: "primary",
        anchor: "#fit-finder",
        anchorPlacement: "append",
      },
    ],
  },
};

// @ts-ignore
window.demo = {
  mockedIntegrationInterfaceData,
};

export const fetchWidgetPDP = function (base: string) {
  return new Promise((resolve) => {
    const scriptElem = document.createElement("script");
    scriptElem.setAttribute("src", `${base}/widget_pdp.js`);
    scriptElem.setAttribute("id", `widget_pdp`);
    scriptElem.setAttribute("type", "text/javascript");
    scriptElem.onload = resolve;
    document.body.appendChild(scriptElem);
  });
};
const fitAnalyticsData = {
  shopLanguage: "en",
  shopCountry: "DE",
  shopSessionId: "fit-finder-demo-ssid-1",
  user: {
    id: "fit-finder-demo-1",
    hasLoggedIn: true,
  },
  mainImageLink:
    "https://images.fitanalytics.com/garments/defshop/300/366182.jpg",
  operations: {
    addToCart: (itemSubgroupId: string, size: string) => {
      console.log("Add to cart", itemSubgroupId, size);
    },
    getSizeChartLink: () => {
      console.log("Get size chart link");
      return document.getElementById("size-chart-link");
    },
  },
  sizes: [
    {
      isAvailable: true,
      value: "S",
    },
    {
      isAvailable: true,
      value: "M",
    },
    {
      isAvailable: true,
      value: "L",
    },
    {
      isAvailable: true,
      value: "XL",
    },
  ],
  currentItemSubgroupId: "01T71Q3-GLW",
  allItemSubgroupIds: ["01T71Q3-GLW", "01T71Q3-BLB"],
};

export const startIntegration = async ({
  fitFinderSdkHost,
  options,
}: IntegrationOptions) => {
  const { bootstrapFitAnalyticsSdk } = (await import(
    // We are aware that vite can not resolve dynamic imports with dynamic strings
    // however, this is not a blocker for us as we don't need to resolve the dynamic import at build time
    /* @vite-ignore */
    `${fitFinderSdkHost}/fit-finder-sdk.js?v=${Date.now()}`
  )) as typeof FitFinderSdk;
  return bootstrapFitAnalyticsSdk().then(async (fitAnalyticsSdk) => {
    const fitFinder = fitAnalyticsSdk.experiences.fitFinder(options);
    if (fitFinder instanceof Error) {
      console.error(fitFinder);
      return;
    } else {
      await fitFinder.start();
    }
  });
};

export const startLegacyIntegration = async ({
  fitFinderSdkHost,
  legacyFitFinderSdkHost,
}: LegacyIntegrationOptions) => {
  //@ts-ignore
  window.fitAnalyticsData = { ...fitAnalyticsData };
  //@ts-ignore
  window._fitAnalytics = ({ Integration, AddOn }) => {
    class FitFinderAddOn extends AddOn {
      hasBeenInitialized = false;
      constructor() {
        super();
        this.api = null;
        this.widget = null;
      }
      async onProductLoaded(_: unknown, details: unknown) {
        if (this.widget) {
          return;
        }
        if (
          details instanceof Object &&
          "garment" in details &&
          details.garment instanceof Object &&
          "category" in details.garment &&
          details.garment.category &&
          //@ts-ignore
          _fitAnalytics.integration.isNewFitFinderTakingOver(
            details.garment.category,
          )
        ) {
          console.log("product load", details.garment.category);

          const options =
            //@ts-ignore
            await _fitAnalytics.integration.getStateForNewFitFinder();
          if (!this.hasBeenInitialized) {
            startIntegration({
              fitFinderSdkHost,
              options,
            }).then(() => {
              this.hasBeenInitialized = true;
            });
          }
        }
      }
    }

    const integration = new Integration({
      debug: true,
      addOns: [new FitFinderAddOn()],
      shop: {
        node: window,
        prefix: "alioop",
        shopLanguage: {
          path: "fitAnalyticsData.shopLanguage",
        },
        country: {
          path: "fitAnalyticsData.shopCountry",
        },
        shopSessionId: {
          path: "fitAnalyticsData.shopSessionId",
        },
      },

      scope: "body",
      product: {
        container: "#product-container",
        currentSerial: {
          node: window,
          path: "fitAnalyticsData.currentItemSubgroupId",
        },
        allSerials: {
          node: window,
          path: "fitAnalyticsData.allItemSubgroupIds",
        },
        thumbnail: {
          node: window,
          path: "fitAnalyticsData.mainImageLink",
        },
        addToCart: (serial: string, size: string) =>
          //@ts-ignore
          window.fitAnalyticsData.operations.addToCart(serial, size),
        sizes: {
          node: window,
          items: {
            path: "fitAnalyticsData.sizes",
          },
          code: {
            path: "value",
          },
          isAvailable: {
            path: "isAvailable",
          },
          shouldSelectRecommended: false,
        },
        hasManufacturedSizes: true,
      },

      button: {
        anchor: "#fit-finder-cta-container",
        anchorPlacement: "append",
        style: {
          button: {
            "background-color": "black",
          },
          image: {
            background: "red",
          },
          text: {
            background: "blue",
          },
          ariaCSS: {
            "background-color": "green",
          },
        },
      },
      extraButtons: [
        {
          key: "secondary",
          anchor: "#fit-finder-secondary-cta-container",
          anchorPlacement: "prepend",
          buttonAttrs: {
            tabindex: "1",
          },
          style: {
            button: {
              background: "yellow",
            },
          },
        },
      ],

      sizeChartButton: {
        elem: "button.size-chart-button",
        shouldHide: false,
      },
    });
    integration.start();
    //@ts-ignore
    _fitAnalytics.integration = integration;
  };
  await fetchWidgetPDP(legacyFitFinderSdkHost);
};

export function App() {
  // TODO: This should be able to set via query params
  const [fitFinderSDKHosts, setFitFinderSDKHosts] = useState([
    {
      name: "Localhost",
      value: "http://localhost:3004",
      selected: true,
    },
    {
      name: "Staging",
      value: "https://fit-finder-sdk-staging.fitanalytics.com/current",
      selected: false,
    },
    {
      name: "Production",
      value: "https://fit-finder-sdk.fitanalytics.com/current",
      selected: false,
    },
  ]);

  // TODO: This should be able to be set via query params with custom values
  const [legacyFitFinderSDKHosts, setLegacyFitFinderSDKHosts] = useState([
    {
      name: "Legacy: Localhost",
      value: "http://localhost:3000",
      selected: true,
    },
    {
      name: "Legacy: Production",
      value: "https://widget.fitanalytics.com",
      selected: false,
    },
  ]);

  useEffect(() => {
    if (shouldInitializeFitFinder()) {
      onFitFinderInit();
    }
  }, []);

  const selectedFitFinderSDKHost =
    fitFinderSDKHosts.find((host) => host.selected)?.value || "";

  const selectedLegacyFitFinderSDKHost =
    legacyFitFinderSDKHosts.find((host) => host.selected)?.value || "";

  const [useLegacyIntegration, setUseLegacyIntegration] = useState(
    shouldUseLegacyInteraction(),
  );
  const onFitFinderInit = () => {
    if (useLegacyIntegration) {
      startLegacyIntegration({
        fitFinderSdkHost: selectedFitFinderSDKHost,
        legacyFitFinderSdkHost: selectedLegacyFitFinderSDKHost,
      });
    } else {
      startIntegration({
        fitFinderSdkHost: selectedFitFinderSDKHost,
        options: mockedIntegrationInterfaceData,
      });
    }
  };
  const [randomProductId, setRandomProductId] = useState("");
  useEffect(() => {
    if (randomProductId) {
      //@ts-ignore
      if (window.fitAnalyticsData?.currentItemSubgroupId) {
        //@ts-ignore
        window.fitAnalyticsData.currentItemSubgroupId = randomProductId;
      }
      mockedIntegrationInterfaceData.product.currentItemSubgroupId.next(
        randomProductId,
      );
    }
  }, [randomProductId]);
  return (
    <>
      <h1>Demo App</h1>
      <br />
      <label>
        <input
          type="checkbox"
          checked={useLegacyIntegration}
          onChange={() => setUseLegacyIntegration(!useLegacyIntegration)}
        />
        Use Legacy Integration Interface
      </label>
      <br />
      <br />
      <select
        value={selectedFitFinderSDKHost}
        onChange={(event) => {
          setFitFinderSDKHosts((x) => {
            return x.map((h) => {
              h.selected = h.value === event.currentTarget?.value;
              return h;
            });
          });
        }}
      >
        {fitFinderSDKHosts.map((host) => (
          <option key={host.name} value={host.value}>
            {host.name}
          </option>
        ))}
      </select>
      <br />
      <br />
      <select
        value={selectedLegacyFitFinderSDKHost}
        onChange={(event) => {
          setLegacyFitFinderSDKHosts((x) => {
            return x.map((h) => {
              h.selected = h.value === event.currentTarget.value;
              return h;
            });
          });
        }}
      >
        {legacyFitFinderSDKHosts.map((host) => (
          <option key={host.name} value={host.value}>
            {host.name}
          </option>
        ))}
      </select>
      <br />
      <br />
      <button data-test-id="fit-finder-init-button" onClick={onFitFinderInit}>
        Initialize Fit Finder
      </button>
      <br />
      <br />
      <button
        onClick={() => {
          setRandomProductId(Math.random().toString(36).substring(7));
        }}
      >
        Random Product ID: {randomProductId}
      </button>
      <button
        onClick={() => {
          setRandomProductId(fitAnalyticsData.currentItemSubgroupId);
        }}
      >
        Reset Product ID
      </button>
      <div id="product-container">
        <div id="fit-finder-cta-container"></div>
        <div id="fit-finder-secondary-cta-container"></div>
      </div>
      <div id="fit-finder"></div>
    </>
  );
}
